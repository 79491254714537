body{
  background-color: #eef2ff;
}

.slack{
  width: 12px;
  height: 12px;
  opacity: .35;
  cursor: pointer;
  margin: 0px 10px;
}

.cog{
  width: 12px;
  height: 12px;
  margin-left: 4px;
  opacity: .6;
  cursor: pointer;
}

.subtitle{
  font-weight: bold;
  font-size: 1.15em;
  font-family: Arial;
  margin-top: 2px;
}

.setting-classification{
  margin: 5px 10px;
  display: flex;
}

.class-title{
  margin-right: 10px;
}

.settings-defaults{
  display: flex;
  background: #dfe4f7;
  padding: 7px;
  border-bottom: 2px solid #d2d8e9;
}

.tag-option{
  border: 1px solid #c1c1c1;
  font-family: Arial;
  margin: 0px 5px;
  background: #fff;
  border-radius: 5px;
  padding: 2px 8px;
  cursor: pointer;
  user-select: none;
}

.settings-container{
  background: #dfe4f7;
  transition: all .5s;
  overflow: hidden;
  padding: 6px 0px;
}

.mobile{
  width: 14px;
  height: 15px;
}

.user{
  margin-right: 4px;
  cursor: copy;
  font-size: 8pt;

}
.terms-body{
  display: flex;
  flex-direction: column;
  float: left;
}

.total-counts{
  font-size: 10pt;
  font-family: Arial,Helvetica,sans-serif;
  margin: 8px;
  float: left;
}

.comments{
  display: flex;
  flex-direction: column;
}

.green{
  font-weight: bold;
  color: green;
}

.info-container{
  font-size: 10pt;
  font-family: Arial,Helvetica,sans-serif;
  display: flex;
}

.info-item{
  margin-right: 10px;
  margin-left: 6px;
}

.message{
  font-family: Arial,Helvetica,sans-serif;
  font-size: 12pt;
  padding: 8px;
}
.comment-container{
  display: table;
  background: #d6daf0;
  padding: 10px;
  margin: 4px;
  border: 1px solid #b7c5d9;
      border-top-color: rgb(183, 197, 217);
      border-top-style: solid;
      border-top-width: 1px;
      border-left-color: rgb(183, 197, 217);
      border-left-style: solid;
      border-left-width: 1px;
  border-left: none;
  border-top: none;
  transition: all .5s;
}








.App {

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app-container{
  display: flex;
  transition: all .15s;
}
.feedback-container{
  transition: all .5s;
}
.profiler-container{
  width: 25%;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100%;
  background: #3e3e4f;
  transition: all .15s;
  overflow-x: hidden;
}

.profiler{
  position: -webkit-sticky;
  position: sticky;
  top:0;
}

.profiler-input{
  position: sticky;
  top: 0;
  text-align: center;
  width: 80%;
  left: calc(10% - 8px);
}


.premium-desc{
  color: gold;
  font-weight: bold;  
}

.user-property-container{
  margin-left: 10%;
  margin-top: 20px;
}

.user-property{
  color: white;
  display: flex;
  font-size: 11pt;
}

.user-property-tag{
  font-weight: bold;
}

.user-property-description{
  margin-left: 11px;
}

.user-property-title{
  margin-top: 30px;
  color: white;
  font-size: 15pt;
  font-weight: bold;
}

.give-premium{
  background: green;
  text-align: center;
  margin-left: 30%;
  margin-right: 30%;
  padding: 8px;
  border-radius: 8px;
  margin-top: 35px;
  color: white;
  cursor: pointer;
}
.remove-premium{
  background: #9d2f2f;
  text-align: center;
  margin-left: 30%;
  margin-right: 30%;
  padding: 8px;
  border-radius: 8px;
  margin-top: 35px;
  color: white;
  cursor: pointer;
}

.no-record{
  margin-top:8px;
  text-align: center;
}

.countdown-timer{
  text-align: center;
  font-size: 2.2em;
  font-weight: bold;
  margin-top: 10px;
  font-family: 'Montserrat';
  color: rgb(87 103 119 / 87%);
}

.up-arrow{
  filter: brightness(1.1) saturate(0);
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  cursor: pointer;
}

.down-arrow{
  filter: brightness(1.1) saturate(0);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.selected-up{
  filter: hue-rotate(75deg) brightness(0.8) saturate(1);;
}

.selected-down{
  filter: hue-rotate(-46deg) brightness(0.8) saturate(1);;
}

.question-title{
  display: flex;
}

.annie-q-rate{
  display: flex;
  flex-direction: column;
  margin-right: 10px;

}

.contributions{
  font-size: 1.4em;
  margin-left: 25px;
  margin-top: 25px;
  font-weight: bold;
}

.contributions-label{
  margin-left: 25px;
  margin-top: 15px;
}

.upload-img{
  width: 60px;
  height: 60px;
  filter: invert(1);
}

.annotate-upload{
  position: absolute;
  bottom: 40px;
  background: #29c3b0;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 1px 1px 2px grey;
  cursor: pointer;
  left: calc(50% - 20px);
}

.annotate-upload:hover{
  background: #28a293;
}

