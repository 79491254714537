body{
  margin:0;
}

.row{
  display: flex;
  position: fixed;
  line-height: 30px;
  box-shadow: 1px 2px 3px #72a7b3;
  z-index: 69;
  width: 100%;
}

.teal{
	background-color: #26d9ff;
}

.title{
  width: 95%;
  font-family: "Montserrat";
  color: white;
  display: inline-block;
  padding: .5% 1%;
}

.q-box{
  width: 90%;
  min-height: 110px;
  box-shadow: 0 4px 4px rgba(0,0,0,.1);
  background-color: white;
  border-radius: 10px;
  margin-top: 50px;
  margin-left: 3%;
  padding: 20px 20px 25px 20px;
}

.test-title{
	font-family: "Montserrat";
	font-size: 1.5rem;
	margin: 10px 14px;
  margin-bottom: 20px;
}


.answer-text{
  font-family: "Montserrat";
  font-size: 1.15rem;
  margin-left: 30px;
  cursor:pointer;
}

.title-name{
  display:inline-block;
  line-height: 45px;
}

.title-input{
  font-size: 1.75rem;
  margin-top: 2px;
}


.flash-pop-container{
  margin-top: 65px;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mark{
  width:24px;
  height:24px;
  position: absolute;
  left: 62px;
}

.loading-box{
  width: 30%;
  height: 30%;
  margin-left: 35%;
}

.edit-icon{
  width: 20px;
  height: 20px;
  margin-left: 10px;
  opacity: .45;
  cursor: pointer;
  float: right;
  margin: 5px;
  background-color: #f6a844;
  padding: 0px;
  border-radius: 11px;
  position: absolute;
  right: 0;
  z-index: 99;
}

.contrib-container{
  position: absolute;
  left: calc(50% - 124px);
  top: 8px;
  color: #26a6b6;
}

.darken{
  opacity: 1;
}

.tag-container{
  margin-left: 15px;
  display: inline;
}

.tag{
  display: inline-block;
  background-color: #ababab;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  color: white;
  font-weight: 800;
  cursor: pointer;
  margin-right: 10px;
  font-size: 1rem;
}

.default-WH{
  display:inline-block;
}

.highlighted-WH{
  display: inline-block;
  background-color: #ddadf2;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.side-ans{
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}

.ans-sub{
  display: inline-block;
  color: #ea2727;
}

.rate-box{
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 5px 0px;
  margin-top: 2px;
  display: inline-block;
  margin: 5px 8px;

}

.rate-btn{
  background-color: #e6e6e6;
  display: inline-block;
  padding: 5px 15px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: "Quicksand";
  cursor:pointer;
}

.selected{
  background-color: red;
}

.faded{
  opacity: .6;
}

.rate-text{
  font-family: "Quicksand";
  display: inline-block;
  font-size: 1.2rem;
}

.rate-box-container{

}

.answer-block{
  background-color: #ffeacf;
  display: inline-block;
  margin: 10px;
  border-radius: 5px;
  padding: 5px 1px;
  font-family: "Montserrat";
  margin-top: 10px;
  position: relative;
}

.ans-container{
  background-color: #fff0de;
  margin: 10px 10px 3px 10px;
  border-radius: 5px;
}

.answer-block-text-container{
  font-size: 1.15rem;
  padding: 3px 15px;
  position: relative;
  display: flex;
  width: 100%;
}

.answer-block-text{
  display: inline-block;
  cursor: default;
  vertical-align: top;
  margin-top: 8px;
}

.answer-block-rating{
  display: inline-block;
  margin-left: 8px;
  padding: 2px 4px;
  cursor: pointer;
}

.block-rate-btn{
  background-color: #e6e6e6;
  display: inline-block;
  padding: 5px 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: "Quicksand";
  cursor: pointer;
  border-radius: 5px;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.07);
}

.rate-img{
  width: 25px;
  height: 25px;
}

.ans-box{
  background-color:#f8f2eb;
}

.ans-btn{
  border-radius: 3px;
}

.red{
  background-color:#ff4b25;
}
.green{
  background-color:#69dd87;
}



.flash-card-back:hover{
  cursor: pointer;
  background-color: #fbc8c8;
}

.topic-text{
  display: inline-block;
  font-size: .85em;
  color: #6060f3;
  margin: -3% -1% 3%;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.bolded-def{
  display:inline-block;
  font-weight: 800;
  color:#8888b6;
  font-size: .6em;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.definition{
  display:inline;
  margin-left: 5px;
  color:#757575;
  font-size: .6em;
  transition: 'all 0s';
}

.facts-container{
}

.fact{
  text-indent: -5px;
  display: flex;
  margin-left: 5px;
  color: #757575;
  font-size: .5em;
  padding: .5% 6px;
  line-height: 1.8em;
}

.card-definition{
  margin-left: 0px;
  transition: 'all 0s';
  margin-bottom: 5%;
}

.fact-title{
  margin-left: 20px;
  font-weight: 400;
  color:#8888b6;
  font-size: calc(.85vw + .85vh);
}
.fact-div{
  margin-left: 40px;
  margin-top: 5px;
  font-size: calc(.7vh + .7vw);
  color: #4d4848;
  cursor:pointer;
}

.source-fact:hover{
  background-color:#ffcece;
}
.source-fact{
  font-style: italic;
}

.default-fact:hover{
  background-color:#f3f3c1;
}



.art-facts-container{
  margin-top: 5px;
}



.inst-text{
  display: inline-block;
  font-size: min(2.25vw, 4vh);
  margin-right: 5%;
  vertical-align: top;
  font-family: "Montserrat";
  color: #7373d7;
  margin-left: 1%;
  margin-top: 1.15%;
}

.inst-text2{
  display: inline-block;
  font-size: 2.25vw;
  margin-right: 12%;
  vertical-align: top;
  font-family: "Montserrat";
  color: #7373d7;
  margin-left: 1%;
}



.tool-container{
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  z-index: 1;
}

.tool-contents{
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 125vh;
  padding: 0% 3%;
}

.just-topic{
  text-align: center;
  height: 100%;
  color: #6a6af6;
  padding: 0% 2%;
}

.just-topic-text{
  width: inherit;
}

.darker-bod{
  background-color: #dadada;
}

.source-fact-text{
    display: inline;
    margin-right: 4px;
    color: #848484;
}
.bold{
  font-weight: bold;
  color:#4d4848;
}

.back-img{
  width: 20px;
  display: inline-block;
  height: 20px;
  padding: 5px;
}



.back-img-container{
  cursor: pointer;
}
.back-img-container:hover{
  background-color:#f3f3c1;
}

.source-n-arrow{
  display: inline-flex;
  width: 95%;
}




.card-container{
  top: 22%;
  position: absolute;
  width: 100%;
}

.flash-card-container{
  background-color: #f3f3f3;
  box-shadow: .5px 1px 1px #bcbcbc;
  width: 48vw;
  min-height: 24vw;
  margin-left: 30%;
  border-radius: 5px;
  font-family: "Montserrat";
  border-color: #cecece;
  border-style: solid;
  border-width: 1px;
  margin-top: calc(18vh - 3vw);
  position: relative;
  padding: 1.5% 2%;
}

.content-container{
  margin-left: 25%;
}



.side-image{
  max-width: calc(15vw);
  max-height:calc(75vh);
  cursor: url(../images/magnify.png), pointer;
  min-width: 15vw;
  background-color: #f7f7f7;
}

.back-container{
    display: inline-block;
    margin-left: 0px;
    margin-top:0px;
}

/* entire container, keeps perspective */
.flip-container {
	perspective: 1000px;
}


/* flip speed goes here */
.flipper {
	transition: 0.6s;
	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
  background: #c8c8c800;

}

.flipped{
  transform: rotateY(180deg);
}

.flipped-x{
    transform: rotateX(180deg);
}

.lifted-card{
  margin-top:-220px;
}

.anim{
	-webkit-transition: all .35s;
  -moz-transition: all .35s;
  -o-transition: all .35s;
  -ms-transition: all .35s;
  transition: all .35s;
}

.all-cards-container{
    transform-style: preserve-3d;
    display: flex;
    flex-flow: wrap;
}

.tiny-cards-container{
  display: flex;
  flex-flow: wrap;
  max-height: calc(83vh);
  width: 100%;
  overflow: auto;
  max-width: 125vh;
}

.scroll-box{
  position: relative;
  min-height: min-content; /* needs vendor prefixes */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 79vh;
  overflow: hidden;
}

.trash-card{
  background-color: #e7adad;
  opacity: 1;
}

.saved-card{
  background-color: #cde9f5;
  opacity: 1;
}

.arrows-img{
  display: inline-block;
  width: 5%;
  cursor: pointer;
}

.space-img{
  cursor: pointer;
  width: 15%;
}

.btn-inst-container{
  display: inline-block;
}



.text-padding-top {
  align-self: flex-start;
}

.text-padding-bottom {
  align-self: flex-end;
}

.tiny-card-text{
  display: inline-block;
  width: inherit;
  font-size: 1.5vw;
}

.tiny-card-highlighted{
  background-color:#f8ffd2;
}

.tiny-card-question-btn{
  position: absolute;
  opacity: .25;
  z-index: 10;
  width: 10%;
  cursor: pointer;
  top: 12%;
  right: 8%;
}

.tiny-card-question-btn:hover{
  opacity: 1;
}

.selected-card{
    background-color:#cde9f5;
}

.completed-card{
  background-color:#aeed9a;
}

.backdrop{
  width: 100vw;
  height: calc(115vh + 7vw);
  position: fixed;
  top: calc(-15vh - 7vw);
  padding-top: 20%;
}

.back-clickable{
  background-color: #0003;
  width:100%;
  height:100%;
  margin-top:-20%;
}

.edit-card-icon{
  opacity: .3;
  width: 15px;
  margin-left: 5px;
  cursor: pointer;
}

.edit-card-icon:hover{
  opacity: .6;
}

.edit-card-button{
  width: 5%;
  cursor: pointer;
  float: right;
  margin: 1% 0%;
}

.definition-textarea{
  display: block;
  width: 100%;
  padding: 5px;
  font-family: "Montserrat";
  height: 100px;
}

.fact-textarea{
  font-family: "Montserrat";
  font-size: .8rem;
  width:100%;
}

.opaque{
  opacity: 1;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.add-icon{
  width: 4%;
  margin-left: 11%;
  margin-top: 1%;
  padding: 2%;
  cursor: pointer;
}

.add-fact-textarea{
  font-family: "Montserrat";
  font-size: .8rem;
  width: calc(100% - 45px);
  margin-left: 40px;
  margin-top: 10px;
}

.left-clickbox{
  width: 29vw;
  height: 100vh;
  background-color: red;
  position: absolute;
  z-index: 100;
  top: -30vh;
  opacity: 0;
}

.right-clickbox{
  width: 29vw;
  height: 100vh;
  background-color: red;
  position: absolute;
  z-index: 100;
  right: -5vw;
  opacity: 0;
  top: -30vh;
}
.tiny-card-container{
  transform-style: preserve-3d;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}
.tiny-card-container:hover{
  transform: scale(1.05);
}

.tiny-card-content{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 'all 0s';
}

.tiny-card{
  background-color: #eaecf7;
  box-shadow: .5px 1px 1px #bcbcbc;
  font-family: "Montserrat";
  border-color: #cecece;
  border-style: solid;
  border-width: 1px;
  width: 91%;
  height: 91%;
  display: inline-block;
  position: relative;
  color: #4b4be1;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tiny-card:hover{
  box-shadow: 2px 5px 5px 1px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.tiny-card-back{
  background-color: #f9f9f9;
  box-shadow: .5px 1px 1px #bcbcbc;
  font-family: "Montserrat";
  border-color: #cecece;
  border-style: solid;
  border-width: 1px;
  width: 87%;
  min-height: 83%;
  display: flex;
  flex-flow: column;
  position: relative;
  color: #4b4be1;
  padding: 5%;
  transition: 'all 0s';
}

.card-content{
  overflow-y: auto;
  transition: 'all 0s';
}

.toolbar-container{
  display: flex;
  float: right;
  justify-content: right;
  margin-right: 1%;
  min-width: 580px;
}
.settings-btn{
  display: inline;
  width: 20px;
  height: 20px;
  margin-right: 1.5vw;
  opacity: .4;
  cursor: pointer;
  
}

.num-cards{
  display: inline;
  line-height: 45px;
  margin-left: 5%;
}

.option-pane{
  width: 98vw;;
  position: absolute;
  background-color: #f4b643;
  right: 1%;
  z-index: 5;
  box-shadow: 1px 1px 2px #7e5305e6;
  border-style: solid;
  border-width: 1px;
  border-color: #cf8f15;
}

.option-container{
  margin-top: -10vh;
}
.option-label{
  font-family: "Montserrat";
  color:#433316;
  font-size: calc(.7vw + .7vh);
}
.option-checkbox{
  display: inline-flex;
  margin-left: .5%;
  margin-bottom: .5%;
}
.checkbox-box{
}

.image-next-image{
  width: calc(.7vh + .7vw);
  padding: 3% 3% 1%;
  float:right;
  cursor: pointer;
}
.image-back-image{
  width: calc(.7vh + .7vw);
  transform: rotateY(180deg);
  padding: 3% 3% 1%;
  float:right;
  cursor: pointer;
}

.focused-image-backdrop{
  position: absolute;
  left: 0;
  top: -9vh;
  width: 100vw;
  height: 101vh;
  background-color: #5a5a5a4d;
}

.focused-image-container{
  position: absolute;
  max-width: 60%;
  max-height: 60%;
  top: 50%;
  left: 50%;
  transform: translateY(-60%) translateX(-50%);
  border: 3px solid #5ba8a8;
}

.focused-image{
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  background-color: #f7f7f7;
}

.image-container{
  background-color: #c3c7f6;
  padding: 2.5%;
  display: inline-block;
  vertical-align: top;
  margin: .2% .1%;
  box-shadow: 1px 1px 1px #0003;
  z-index: 150;
}

.side-container{
  display: inline-block;
  vertical-align: top;
}
.wiki-container{
  background-color: white;
  margin: 1.5%;
  padding: 5%;
  color: #0645ad;
  font-family: sans-serif;
  cursor: pointer;
  box-shadow: 1px 1px 0px #b3aeae;
  border-radius: 2%;
}

.wiki-img{
  height: calc(.6vh + .6vw);
  box-shadow: 0px 0px .5px #5a5454;
  margin-right: 5%;
  border-radius: 3px;
  padding: 2%;
  display: inline-block;
}

.wikilink-text{
  display: inline-block;
  font-size: calc(.6vh + .6vw);
  vertical-align: top;
  margin: calc(.1vh + .1vw) 0px 0px 0px;
}

.trash-empty{
  width: 10%;
  margin-right: 8%;
}

.trash-full{
  width: 10%;
  margin-right: 8%;
  cursor: pointer;
}

.trash-full:hover{
  margin-top: -5%;
}

.trash-icon{
  width: 100%;
}

@keyframes straightLine {
  50% {
    transform: translate3D(100px, -100px, 0);
  }
}

.dot {
  animation: straightLine 2.5s ease-out;
}


[contenteditable="true"]:active,
[contenteditable="true"]:focus{
  border:none;
  outline:none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff7a; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #72829038;; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.pic-container{
  position: relative;
  max-width: 40%;
  max-height: 70%;
  background: #eaeaea;
  border-radius: 4%;
  float: right;
  text-align: center;
  user-select: none;
}

.loading-img{
  width: 60%;
  margin-top: 10%;
}

.card-img{
  max-height: 100%;
  max-width: 100%;
  border-radius: 1%;
  user-select: none;
}

.no-bg{
  background: none;
}

.left-img-action{
  position: absolute;
  height:100%;
  width:30%;
  cursor: url(../images/left-arrow.png), pointer;
  user-select: none; 
}

.center-img-action{
  position: absolute;
  height:100%;
  width:40%;
  left: 30%;
  cursor: url('../images/magnify.png'), auto;
  user-select: none; 
}

.right-img-action{
  position: absolute;
  height:100%;
  width:30%;
  left: 70%;
  cursor: url('../images/right-arrow.png'), auto;
  user-select: none; 
}

.full-image{
  position: absolute;
}

.add-more-cards{
  position: absolute;
  position: absolute;
  font-size: 2em;
  color: #7373d7;
  cursor: pointer
}

.study-button{
  display: flex;
  cursor: pointer;
  width: 40%;
  align-items: center;
}

.study-button:hover{
  margin-top: -10%;
}

.nerd-img{
  width: 30%;
  margin: 3%;
}

.fader{
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #e8eff5;
  top: 0;
  left: 0;
  z-index: 9000;
}

.study-container{
  background: #e8eff5;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  margin-top: 4%;
}
.growth-pane{
  margin-bottom: 300px;
}

.questions-container{
  background: #fff;
  height: 100%;
  width: 70%;
  margin-left: 15%;
  margin-top: 2%;
  margin-bottom: 300px;
  overflow: auto;
}

.question-container{
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 3%;
  padding: 2%;
}

.question-title{
  font-size: 2em;
  font-family: "Montserrat";
  font-weight: bold;
}

.answers-container{
  margin: 14px 10px;
  display: flex;
  flex-direction: column
}

.answer-container{
  display: flex;
  flex-direction: row;
  line-height: 40px;
  cursor: pointer;
  width: fit-content;
  padding-right: 15px;
  border-radius: 18px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.answer-text{
  margin-left:15px;
}


.more-questions-btn{
  width: fit-content;
  margin-left: 5%;
  font-size: 2em;
  padding: 10px;
  color: #6720ef;
  font-family: "Montserrat";
  cursor: pointer;
}

.quiz-option-container{
  position: fixed;
  top: 8%;
  height: 50%;
  left: 85%;
  width: 8%;
}

.quiz-type-img{
  width: 30%;
  margin: 20%;
  cursor: pointer;
}

.quiz-fib{
  font-size: 2em;
  padding: 5px;
  border: none;
}

.quiz-fib-distractor{
  font-size: 1.5em;
  padding: 5px;
  border: none;
}

.correct-fib{
  background: #8ae68a;
  font-size: 2em;
  padding: 5px 20px;
  border-radius: 30px;
  
}

.quiz-checkmark{
  width: 20px;
  height: 20px;
  padding: 13px 8px 13px 0px;
  cursor: default;
}

.quiz-xmark{
  width: 20px;
  height: 20px;
  margin-right: 7px;
  cursor: pointer;
}

.distractors-container{
  margin-left: 20px;
}

.distractor-container{
  display: flex;
  align-items: center;
}

.quiz-distractor-loading{
  width: 25px;
  height: 25px;
}