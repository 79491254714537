
.console-container{
	background-color: rgb(50, 46, 46);
	color: rgb(231, 231, 231);
	padding: 5px 2px;
	font-weight: 800;
	position: fixed;
	z-index: 99;
	left: 0px;
	bottom: -230px;
	width:100%;
	height: 250px;
  box-shadow: 1px 1px 3px black;
}

.console-tab-btn{

  width: intrinsic;           /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content;    /* Firefox/Gecko */
  width: -webkit-max-content; /* Chrome */
  background-color: #1e1e1e;
  padding: 3px 10px;
  cursor: pointer;
  display: inline;
  margin-right: 2px;
  font-size: 1rem;
  margin-left: 1px;

}

.console-output{
	display: block;
  width: calc(100% - 17px);
	margin-left: 3px;
	margin-top: 7px;
	height: calc(100% - 80px);
	background-color: #181818;
	overflow: auto;
	padding: 0px 3px;
}

.console-input{
	margin-top: 5px;
	margin-bottom: 4px;
	background-color: #181818;
	border: white;
	margin-left: 3px;
	width: calc(100% - 12px);
	color: green;
	padding: 3px 4px;
}

.console-mini-text{
	font-weight: 100;
	margin-left: 4px;
	color: #9e9e9e;
}

.console-open{
  bottom:-50px;
}

.tab-highlighted{
  background-color: #456d5b;
}

.feedback-textarea{
  width: 1003px;
  height: 169px;
  background-color: #1c1c1c;
  border: none;
  color: white;
  font-size: 1rem;
}
