body{
    font: 13px 'Helvetica Neue',Helvetica,Arial,sans-serif;
    color: #333;
    overflow: auto;
}


.floaty-box {
    min-height: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 15px 25px;
    margin: 6px;
    height: auto;
}

.new-question{
    min-height: 0;
    overflow-y: auto;
    background-color: #efeff4;
    -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.1);
    box-shadow: 0 1px 4px rgba(0,0,0,.1);
    padding: 15px 25px;
    margin: 6px;
    opacity: 0;
    position: absolute;
    margin-bottom: 20px;
    width: calc(100% - 60px);
    top: 65px;
    z-index: 20;
}

.anim{
   -webkit-transition: all .15s;
  -moz-transition: all .15s;
  -o-transition: all .15s;
  -ms-transition: all .15s;
  transition: all .15s;
  -webkit-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
  -moz-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
  -o-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
  -ms-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
  transition-timing-function: cubic-bezier(.694,.0482,.335,1);
}


.login-input{
	width: 100%;
	border: white;
	height: 30px;
	border-radius: 5px;
	padding: 8px;
	margin: 10px 0px;
}

p{
	padding: 0px;
	margin: 0px;
}

.name{
	color:rgb(118, 239, 118);
}
.num-answered{
	color: white;
	position: absolute;
	top: -25px;
	font-size: 20px;
	font-weight: 800;
	left: calc(50% - 124px);
}



.rate-box{
	background-color: #f6f6f6;
	padding: 5px 10px;
	margin-bottom: 30px;
}

.num-box{
	background-color: #e3e3e3;
	width: 20px;
	height: 20px;
	display: inline;
	padding: 5px 12px;
	margin-left: 2px;
	cursor: pointer;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    -ms-transition: all .1s;
    transition: all .1s;
    -webkit-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
    -moz-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
    -o-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
    -ms-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
    transition-timing-function: cubic-bezier(.694,.0482,.335,1);

}

.extra-left-margin{
	margin-left: 80px;
}

div{
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    -ms-transition: all .4s;
    transition: all .4s;
    -webkit-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
    -moz-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
    -o-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
    -ms-transition-timing-function: cubic-bezier(.694,.0482,.335,1);
	transition-timing-function: cubic-bezier(.694,.0482,.335,1);
	text-rendering: optimizelegibility;

}
.letter{
	font-size: 18px;
	font-weight: 800;
}
.spacie{
	margin-left:20px;
}

.spacie2{
	margin-left:40px;
}


.shadder{
	width:100%;
	height:100%;
	position:absolute;
	opacity:0;
	top: 0;
	left: 0;
}

.graded{
	background-color:#2ea822;
}
.skipped{
	background-color: #ffcf00;
}



.toolbar{
	width: calc(100% - 26px);
	height: 50px;
	background-color: #e3e3e3;
	-webkit-box-shadow: 0 1px 4px rgba(0,0,0,.1);
	box-shadow: 0 1px 4px rgba(0,0,0,.1);
	opacity: 1;
}

.toolbar-text{
  top: -3px;
  cursor: pointer;
  padding-right: 30px;
  font-size: 20px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.ans{
	margin-left: 20px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.notepad{
	position: absolute;
	top: 20%;
	left: 15%;
	background-color: #fff;
	z-index: -1;
	width: 70%;
	height: 40%;
	-webkit-box-shadow: 0 1px 4px rgba(0,0,0,.1);
	box-shadow: 0 1px 4px rgba(0,0,0,.1);
	border-radius: 2px;
	padding: 15px;
	opacity:0;

}

.note-text{
	width: 100%;
	height: 76%;
	font: 20px "Helvetica Neue",Helvetica,Arial,sans-serif;
	border: white;
}
.notepad-inst{
	font-size:18px;
}

.announcement{
	position: relative;
	left: 40px;
	width: calc(100% - 100px);
}
.announcement-title{
	font-size: 22px;
	margin-bottom: 5px;
}
.announcement-text{
	font-size: 16px;
	margin-left:20px;
	margin-bottom: 5px;
}

.inline{
	display: inline;
}
.content-upload{
	margin-left:20px;
	padding:6px;
}

.steps-upload{
	display: inline-block;
	margin-left: calc(50% - 250px);
}

.parse-btn{
	float: right;
	margin-top: 10px;
	background-color: rgb(76, 175, 80);
	color: white;
	text-decoration: none;
	cursor: pointer;
	font: 13px 'Helvetica Neue',Helvetica,Arial,sans-serif;
	border: none;
	padding: 5px 10px;
	font-weight: 800;
	border-radius: 3px;
	box-shadow: 0 2px 5px rgba(0,0,0,.18);
}

.parse-container{
	width: 100%;
	padding-bottom: 25px;
	margin-top: 600px;
	background-color:#efeff4;
	overflow-y:auto;
	opacity:0;
	margin-bottom: 80px;
}

.pOut-title{
	margin-left: 25px;
}

.pOut-container{
	margin: 0px 25px;
	background-color: rgb(252, 252, 252);
	padding: 20px;
}

.sentence-mode-btn{
	float: right;
	margin-right: 30px;
	margin-top: -28px;
	cursor: pointer;
}

.cluster{
	padding-top: 8px;
	padding-bottom: 8px;
	-webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    -ms-transition: all .1s;
    transition: all .1s;
    cursor:pointer;
}

.cluster:hover {
    background-color: rgb(200, 245, 199);
}

.parse-instruction{
	margin-left:25px;
	font-size: 14px;
	margin-bottom: 3px;
	font-weight: 800;
}

.settings-btn{
	margin-right: 30px;
	padding: 10px 20px;
}
.qRadio{
	margin-left: 65px;
	margin-top: -29px;
	margin-bottom: 15px;
}
.genInputH{
	margin-left: 5px;
	position: absolute;
	margin-top: 5px;
}
.genLink{
	font-weight: 800;
	margin-left: 25px;
	color: #6060ff;
	margin-bottom: 5px;
	cursor: pointer;
	font-style: normal;
}
.genMeta{
	margin-left: 25px;
	margin-bottom: 12px;
	font-style: italic;
}

.verbose{
	margin-left:25px;
	font-size:14px;
}

.small{
	font-size: 12px;
}

.semGrade{
	padding: 5px;
	display: inline;
	padding: 3px 8px;
	margin-left: 20px;
	cursor:pointer;
	background-color: #969696;
	color: #e6e6e6;
	border-radius: 4px;
	font-weight: 800;
	box-shadow: 0 2px 2px rgba(5, 5, 5, 0.5);

	-webkit-transition: all 0s;
    -moz-transition: all 0s;
    -o-transition: all 0s;
    -ms-transition: all 0s;
    transition: all 0s;
}

.green-btn{
	background-color: #bbedb3;
}
.red-btn{
	background-color: #edb3b3;
}
.blue-btn{
	background-color: #b3beed;
}

.official{
	margin-top:15px;
	cursor: pointer;
	padding: 10px;
	margin-left: 10px;
}
.official:hover{
	background-color: #fafafa;
}

.hint{
	cursor:pointer;
	background-color: #abeab0;
	padding: 5px 10px;
	overflow: hidden;
}

.hintP{
	margin-bottom: 10px;
	margin-top: 10px;
}

.h-left{
	float: right;
	margin-right: 35px;
	font-size: 14px;
	font-weight: 800;
}
.qg-output{
	margin: 20px 25px;
	background-color: rgb(117, 117, 117);
	padding: 0px 0px;
	color: rgb(239, 239, 239);
	font-weight: 800;
	position:relative;
	height: 30px;
}

.sentence{
	margin-left: 25px;
	font-weight: 800;
}

.test-type{
	font-size: 25px;
	position: absolute;
	top: 65px;
	left: 20px;
	cursor:pointer;
  float:right;
}

.test-name{
  position: absolute;
  top: 60px;
  left: 5px;
  font-size: 25px;
  width: 300px;
  font-family: "Quicksand";
  border-radius: 8px;
  background: linear-gradient(#f6f6f6,white);
  border-color: #B1B1B1;
  border-style: double;
  box-shadow: inset 0 0 3px #c9c6c6;
  height: 41px;
  padding: 0px 8px;
}

.hints-link{
	left:200px;
}

.def-link{
	left:340px;
}

.warning{
	top: 200px;
	left: calc(50% - 200px);
}

.page-link{
	font-weight: 800;
	color: rgb(89, 89, 255);
	text-decoration: none aliceblue;
	margin-top: -10px;
	position: absolute;
}

.pregen{
	font-weight: 800;
}

.light-blue {
	color: rgb(89, 89, 255);
	cursor: pointer;
}

.info-table{
	background-color: #ddd;
	padding: 2px 2px;
}
.info-td{
	color: #fff;
	background-color: #5d5757;
	padding: 0px 50px;
}

.tcont{
	background-color: #f8f8f8;
	font-size: 12px;
	font-weight: 800;
	color: rgb(89, 89, 255);
	cursor: pointer;
}

.tuser{
		background-color: #f8f8f8;
		font-size: 12px;
		font-weight: 800;
		cursor: initial;
		color: rgb(132, 132, 132);
}

.green-bg{
	    background-color: rgb(94, 137, 69);
}

.add-tag{
	position:absolute;
	z-index: -1;
	opacity: 0;
	background-color: rgb(59, 56, 56);
	padding: 10px;
	border-radius: 5px;
	left: calc(50% - 220px);
	top: 25%;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
	color:#e9e9e9;
}

.red{
	background-color: rgb(175, 76, 76);
}



.load-text{
	z-index: 5;
	position: absolute;
	font-size: 16px;
	margin: 5px 8px;
}

.load-bar{
	position: absolute;
	width: 0%;
	height: 100%;
	background-color: rgb(73, 81, 116);
}

.green-blue{
	background-color: rgb(73, 108, 116);
}

.light-green{
	background-color: rgb(77, 116, 73)
}


.stdout{
	margin: 3px;
}

.ticker-tag{
	position:absolute;
	top:110px;
	margin-left:5px;
	width:130px;
	padding: 5px 10px;
	text-align: right;
}

.construction-container{
	margin-left:10px;
	min-height: 400px;
}

.const-title{
	font-size: 20px;
	margin-bottom: 10px;
	font-weight: 800;
	margin-top: 10px;
}

.const-state{
	margin-left: 25px;
	font-size: 15px;
	margin-top: 5px;
}

.const-label-state{
	margin-left: 25px;
	font-size: 15px;
	margin-top: 5px;
}

.const-subtitle{
	font-size: 17px;
	margin-left: 20px;
	font-weight: 800;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.bar {
  fill: steelblue;
}

.x.axis path {
  display: none;
}

.graph-rect-area{
	position: absolute;
	top: 450px;;
	right: 100px;
}

.mess-container{

}

.half-box{
	width: 50%;
}

.multi-val{
	margin:5px;
	display: table-cell;
	width:-moz-max-content;
	min-width: 50px;
}

.editable{
	cursor:pointer;
	padding: 5px 8px;
}

.editable:hover{
	background-color: #fafafa;

}


.confidence{
	font-size: 20px;
	margin-top: 10px;
}

.confy{
	display: inline;
}

.no-anim{
	-webkit-transition: all 0s;
    -moz-transition: all 0s;
    -o-transition: all 0s;
    -ms-transition: all 0s;
    transition: all 0s;
}


.server-checker{
  position: absolute;
  float: right;
  right: 1%;
  top: 0.5%;
  font-family: "Montserrat";
  opacity: .7;
}

.inline-text{
  display:inline-block;
}

.red-text{
  color:red;
}

.green-text{
  color:green;
}
