.abcRioButton{
	height: 36px;
	width: 30%;
	margin-left: calc(50% - 120px);
	margin-top: 5%;
}

body{
	margin: 0;
}
.everything{
	width: 850px;
    margin: 0 auto;
	height: 100vh;
	position: relative;
}




.floaty-box{
	 position: relative;
	 overflow: auto;
}


.upload-btn{
	margin-top: 2%;
	font-family: Quicksand;
	background-color: rgb(93 135 179);
	color: white;
	text-decoration: none;
	cursor: pointer;
	font-size: 2vh;
	border: none;
	font-weight: 800;
	border-radius: 3px;
	box-shadow: 0 2px 5px rgba(0,0,0,.18);
	margin-left: 2.5%;
	width: 20%;
	height: 5.5%;
	margin-bottom: 2%;
	margin-right: 3%;
	line-height: 40px;
	transition: all 1s;
}

.medical{
	background-color: #008aff;
	margin-right: 220px;
}

.cancel{
	background-color: #bd5943;
}

.or-txt{
	font-size: 2vw;
	display: inline;
	color: #fffcfc;
}

.hidden{
	display:none;
}

.upload-title{
	font-size:2.5vh;
	color:white;
	margin-bottom: 5px;
	font-family: 'Quicksand';
  font-weight: bold;
}

.input-textarea{
	width: 400px;
	height: 280px;
	box-shadow: inset 0 0 3px #aaa;
	border: none;
	border-radius: 5px;
	font-size: 1.3em;
    padding: 20px;
    line-height: 1.6em;
}

.input-url{
	width: 100%;
	border: none;
	border-radius: 8px;
	font-size: 3vh;
	color: #747070;
	padding: 1%;
}

.source-btn{
	float: right;
	margin-top: 0px;
	margin-bottom: 2px;
	margin-right: 4px;
}



.load-bar-container{
	width: 100%;
	height: 100%;
	position: relative;
	display: inline-block;
}

.load-bar-border{
	position: absolute;
	background-color: rgba(243, 243, 243, 0);
	border-radius: 5px;
	box-shadow: inset 0 0 5px #aaa;
	width: 100%;
	height:100%;
	z-index: 3;
}

.load-bar-background{
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 5px;
}

.load-bar-fill{
	position: absolute;
	width: 1%;
	height: 100%;
	background-color: #00ff81;
	z-index: 2;
	border-radius: 5px;
}

.load-bar-text{
	position: absolute;
	top: 30%;
	margin-left: 1%;
	font-weight: 800;
	font-size: 2.0vh;
	color: rgb(77, 73, 73);
	z-index: 50;
	width: 100%;
}

.finish-button{
	width:20%;
	background-color: rgb(36, 202, 174);
	border-radius: 5px;
	box-shadow: 0 0 3px #989696;
	font-weight: 800;
	font-size: 2.0vh;
	color: white;
	position: absolute;
	display: inline-block;
	text-align: center;
	margin-left: 3%;
	top: 47px;
	height: calc(100% - 62px);
	opacity: 0;
	cursor: pointer;
}

.finish-button-text-test{
	margin-top: 1.8vh;
}

.lucky-btn{
	width:24%;
	background-color:rgb(36, 202, 174);
	height: 100%;
	margin-top: 0;
	display: inline-block;
	position: initial;
}

.finish-button-text-construciton{
	margin: 1.7vh 10%;
}

.dark-green{
	background-color: rgb(37, 177, 38);
}

.qg-title-container{
	transition: all .8s;
	z-index: 60000;	
	padding: .5%;
	display: flex;
	min-height: fit-content;
	display: flex;
	justify-content: center;
	margin-top: 5%;
}

.qg-title{
	height: calc(100% - 6px);
}

.subtitle-text{
	font-size: 1.6em;
    color: rgb(160, 165, 170);
    font-style: italic;
    font-family: 'Josefin Sans';
    font-weight: bold;
}

.input-container{
	margin: 0;
	transition: all 0s;
	position: relative;
	z-index: 20;
	overflow: hidden;
	padding-top: 50px;
}

.welcome-back{
	background-color: #f9ff00;
	font-size: 18px;
  font-weight: bold;
  padding: 15px 20px;
  cursor: pointer;
}


.inner-container{
	margin-left: 2%;
	width: 86%;
    margin-top: 1.5%;
    margin-left: 5%;
}

.mess-container{
	margin-top: 1%;
	margin-left: 5%;
}

.desc-text{
	font-size: 2em;
	margin-top: 1%;
	font-family: 'Quicksand', sans-serif;
	font-weight:bold;
	display: inline;
}

.desc-more-text{
	font-size: 2em;
	margin-left: 1%;
	margin-top: 1%;
	font-family: 'Quicksand', sans-serif;
	display: inline;
}

.url-container{
	background-color: #56D7FB;
	overflow: auto;
	padding: 3% 8%;
	box-shadow: 1px 1px 1px #c4c3c3;
	position: relative;
}

.front-container{
	float: right;
	width: 100%;
	z-index: 2;
    transform: rotateY(0deg);
}

@media (max-height: 600px) {
  .subtitle-text-1{
    font-size: 27px
  }
   .subtitle-text-2{
    font-size: 27px
  }
  .description-container{
  	margin-top:110px;
  }
}

.background-image{
}

.tit-img-container{
	display: inline-block;
	height: 13vh;
}

.slogan-container{
	display: inline-block;
	margin-top: 1.25vh;
	position: relative;
}

.auth-cover{
	position: absolute;
	background-color: #d7d7d7;
	width: 100%;
	height: calc(100% - 11%);
	top: 7.5vh;
	left: 0;
	opacity: 0;
	text-align: center;
	overflow: auto;
	z-index: 600;
	display: none;
}

.auth-title{
	text-align: center;
	font-size: 3rem;
	font-family: 'Quicksand';
	margin-top: 50px;
	display: inline;
	color:#4E4D4D;
}

.auth-title-container{
	text-align:center;
}

.bold{
	font-weight: bold;
	font-family: 'Josefin Sans';
	display: inline-block;
	color:#323232;
}

.auth-input{
	display: inline-block;
	font-size: 3rem;
	text-align: center;
	margin-top: 25px;
	width:75%
}

.auth-text{
	font-size: 1.1rem;
	width: 55%;
	margin-left: 22.5%;
	text-align: center;
	margin-top: 80px;
}

.google-btn{
	cursor: pointer;
	display: inline-block;
	margin-left: -10%;
	overflow: hidden;
	width: 20%;
	position: absolute;
	margin-top: 26px;
	border-radius: 5px;
	box-shadow: 1px 1px 5px #0000001a;
	border-color: #0000001A;
	border-width: 2px;
	border-style: solid;
}

.auth-input-conatiner{
	width: 100%;
}

.google-mask{
	background-color: white;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	pointer-events: none;
}

.google-icon{
	margin-top: 23px;
	width: 25px;
	height: 20px;
	position: absolute;
	left: 20px;
}

.top-auth{
	margin-top:10px;
	margin-bottom:10px;
}

.g-btn-txt{
	font-size: 20px;
	top: 22px;
	margin-left: 5.5%;
	color:#898989;
	font-family:"Montserrat";
	position:absolute;
}

.g-btn-content{
	margin-left: calc(50% - 40px);
}
.pre-auth-div{
	display: block;
	margin-top: 100px;
}

.auth-login{
	height:70px;
	position: relative;
	width:200;
}

.invalid-box{
	margin-top: 20px;
	width: 58%;
	margin-left: 20%;
	padding: 8px 0px;
	font-size: 18px;
	color: #fb0000;
	border-style: solid;
	display: none;
}

.invalid-text{
	color: #ff0000;
	position: absolute;
	font-size: 1.2rem;
	top: 75%;
	left: 9%;
	font-family: 'Quicksand';
	font-weight: bold;
	opacity: 0;
}

.load-bar-float{
	background-color:rgba(0, 75, 255, 0.6);
	padding: 3% 9%;
}

.red{
	color:red;
}
.green{
	color:green;
}

.orange-text{
	color:orange;
}

.my-label{
	font-size: 2vh;
	margin-left:3px;
}
.my-check-box{
	margin-top: 4px;
}
.check-box-container{
	position: absolute;
	margin-top: 1%;
	color: #43438d;
	width:25%;
}

.checkbox-container{
	margin-right: 2vw;
	display: inline-flex;
}

.content-container{
	position: relative;
	display: flex;
	width:100%;
	height: 6vh;
}


.char-counter{
    font-family: 'Montserrat';
    margin: 5px;
    margin-left: 5px;
    color: #1e252b;
    font-size: 1em;
    width: 100%;
	display: flex;
    justify-content: space-between;
}



.parse-container{
	background-color:red;
}

.quiz-container{
	background-color:green;
}


.loading-buttons-container{
	background-color: rgba(0, 75, 255, 0.6);
	padding: 3% 9%;
	opacity: 0;
	display: flex;
	justify-content: space-evenly;
}

.loading-btn{
	margin-top: 1%;
	background-color: rgb(75, 200, 152);
	color: white;
	text-decoration: none;
	cursor: pointer;
	font-size: 2em;
	border: none;
	border-radius: 3px;
	box-shadow: 0 2px 5px rgba(0,0,0,.18);
	margin-left: 2.5%;
	width: 30%;
	height: 5.5%;
	margin-bottom: 1%;
	margin-right: 3%;
	line-height: 50px;
	text-align: center;
	font-weight: bold;
}

.loading-btn:hover{
	background: rgb(72 179 138);
}

.loading-btn-container{
	display: flex;
	position: relative;
	margin-top: 1%;
	background-color: rgb(136, 210, 182);
	color: white;
	text-decoration: none;
	cursor: pointer;
	font-size: 2vh;
	border: none;
	border-radius: 3px;
	box-shadow: 0 2px 5px rgba(0,0,0,.18);
	margin-left: 2.5%;
	width: 30%;
	height: 5.5%;
	margin-bottom: 1%;
	margin-right: 3%;
	line-height: 50px;
	text-align: center;
	font-weight: bold;
}

.loading-btn-bar{
	position: absolute;
	background-color: #5cb593;
	height: 100%;
}

.loading-btn-title{
	position: absolute;
	width: 100%;
}

.hidden-title{
	opacity: 0;
}

.browser-warning{
	background: #d3d9ea;
	display: flex;
	justify-content: center;
	font-weight: bold;
	padding: 5px;
	cursor: pointer;
}

.chrome-icon{
	width: 15px;
	margin-left: 6px;
	margin-right: 2px;
}

.feedback-container{
	display: flex;
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
	justify-content: center;
	left: 10%;
	width: 80%;
    height: 90%;
    left: 10%;
    border-radius: 8px;
    box-shadow: 0px 9px 17px 0px grey;
}

.title-feedback-btn{
	width: 140px;
    background: #007bff;
    display: flex;
    height: fit-content;
    padding: 10px;
    border-radius: 5px;
    justify-content: center;
    font-size: .8em;
	cursor: pointer;
	max-width: 140px;
}

.title-feedback-btn:hover{
	background: #3170b5;
}



.feedback-first-box {
	position: absolute;
	top: -32px;
	left: 179px;
	background-color: #fff;
	border-radius: 15px;
	border: 1px solid #aaa;
	padding: 10px;
	width: 250px;
	pointer-events:	auto;
	z-index: 1000;
	transition: all 1s;
  }
.feedback-title{
	color: #619c4e;
	font-family: Lato, sans-serif;
	font-size: 1.25em;
	text-align: center;
	font-weight: bold;
}

.feedback-warning{
	color: #d23333;
}

.feedback-text{
	font-family: Lato;
	margin-top: 4px;
	margin-bottom: 4px;
	font-size: .85em;
	color: #595757;
}

.feedback-box{
	position: absolute;
	pointer-events:	auto;
	top: -26px;
	background-color: white;
	padding: 5px;
	left: 178px;
	width: 300px;
	height: 120px;
	border: 1px solid #c8c6c6;
}

.feedback-discord2{
	background-color: #526bbc;
	padding: 5px;
	font-family: Montserrat;
	color: white;
	cursor: pointer;
	position: absolute;
	font-size: .7em;
	font-weight: bold;
	transition: all .15s;
	width: 49px;
	text-align: center;
	margin-top: 3px;
	right: 118px;
	height: 14px;
}

.feedback-discord2:hover{
	background: rgb(64, 85, 155);
}

.feedback-textarea{
	width: 100%;
	height: 91px;
	padding: 5px;
	font-size: .75em;
	font-family: Lato, sans-serif;
}

.feedback-btn{
	background-color: #619c4e;
	padding: 8px;
	margin-top: 8px;
	margin-left: 30px;
	font-family: Montserrat;
	color: white;
	cursor: pointer;
	font-size: .7em;
	font-weight: bold;
	transition: all .15s;
	width: 100px;
	text-align: center;
}

.feedback-btn2{
	background-color: #499557;
	padding: 5px;
	font-family: Montserrat;
	color: white;
	cursor: pointer;
	position: absolute;
	font-size: .7em;
	font-weight: bold;
	transition: all .15s;
	width: 100px;
	text-align: center;
	margin-top: 3px;
	right: 5px;
}

.feedback-btn2:hover{
	background-color: #25a380;
}

.feedback-btn:hover{
	background-color: #25a380;
}

.feedback-content{
	display: flex;
	margin-top: 10%;
    margin-left: -5%;
}

.feedback-subtitle{
	font-size: 2em;
    font-family: 'QUICKSAND';
    color: #d02121;
}

.feedback-message{
	font-size: 1.4em;
	margin-bottom: 10px;
}

.feedback-textarea-input{
	width: 550px;
	height: 160px;
	font-size: 1.2em;
    padding: 15px 10px;
    font-family: Arial;
}

.discord-img{
	filter: brightness(0) invert(1);
    width: 40px;
    height: 40px;
    padding: 5px;
}

.feedback-buttons{
	display: flex;
	padding: 8px;
	cursor: pointer;
}

.discord-btn{
	font-size: 1.3em;
	color: #499557;
	line-height: 33px;
	padding: 0px 5px;
}

.discord-btn:hover{
	background: #f7f7f7;
	color: #40ab55;
}

.feedback-send-btn{
	font-size: 1.3em;
    color: white;
    background: #499557;
    padding: 7px 14px;
	border-radius: 4px;
	margin-left: 5px;
}

.feedback-send-btn:hover{
	background: #40ab55;
}

.close-btn{
	cursor: pointer;
    position: absolute;
    right: 4%;
    top: 3%;
    font-size: 2.25em;
    color: gray;
}

.close-btn:hover{
	color: black;
}

.alpha-image-container{
	position: absolute;
    z-index: 1;
    background: #4847476e;
    width: 100%;
	height: 100%;
	display: flex;
    justify-content: center;
	align-items: center;
	flex-direction: column;
}

.alpha-image{
	width: 440px;
    height: 330px;
    background: white;
    border-radius: 10px 10px 0px 0px;
}

.alpha-warning-text{
	background: white;
    padding: 13px;
    font-size: 1.5em;
    width: 414px;
    text-align: center;
	font-weight: bold;
	border-radius: 0px 0px 10px 10px;
	cursor: default;
}

.input-box-container{
	transition: all 1s;
}

.submition-button{
	transition: all 1s;
}

.start-anim{
	position: absolute;
    top: -113px;
	left: -63px;
	pointer-events: none;
}
.start-title-image{
	transform: translate(0, 20px);
	height: 100%;
}