body{
  overflow-y: hidden;
  overflow-x: hidden;

}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 5.35px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4.3px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: rgba(91, 130, 172, 0.85);
  border-radius: 4.9px;
  border: 0.1px solid #000101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0.8px 0.8px 1px #000000, 0px 0px 0.8px #0d0d0d;
  border: 0.4px solid #000000;
  height: 15px;
  width: 10px;
  border-radius: 6px;
  background: #333232;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.45px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: rgba(128, 158, 191, 0.85);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 4.3px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: rgba(91, 130, 172, 0.85);
  border-radius: 4.9px;
  border: 0.1px solid #000101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0.8px 0.8px 1px #000000, 0px 0px 0.8px #0d0d0d;
  border: 0.4px solid #000000;
  height: 15px;
  width: 10px;
  border-radius: 6px;
  background: #333232;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 4.3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: rgba(70, 102, 137, 0.85);
  border: 0.1px solid #000101;
  border-radius: 9.8px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: rgba(91, 130, 172, 0.85);
  border: 0.1px solid #000101;
  border-radius: 9.8px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 0.8px 0.8px 1px #000000, 0px 0px 0.8px #0d0d0d;
  border: 0.4px solid #000000;
  height: 15px;
  width: 10px;
  border-radius: 6px;
  background: #333232;
  cursor: pointer;
  height: 4.3px;
}
input[type=range]:focus::-ms-fill-lower {
  background: rgba(91, 130, 172, 0.85);
}
input[type=range]:focus::-ms-fill-upper {
  background: rgba(128, 158, 191, 0.85);
}


.row{
	width: calc(100% - 24px);
	min-height: 50px;
	margin-left: -8px;
	padding: 5px 20px;
  position:fixed;
  box-shadow: 1px 2px 3px #72a7b3;
  z-index: 69;
}

.teal{
	background-color: #26d9ff;
}

.title{
	margin-top: 3px;
	font-family: "Montserrat";
	font-size: 2rem;
	color:white;
	display: inline-block;
}

.q-box{
  width: 90%;
  min-height: 110px;
  box-shadow: 0 4px 4px rgba(0,0,0,.1);
  background-color: white;
  border-radius: 10px;
  margin-top: 50px;
  margin-left: 3%;
  padding: 25px 25px 15px 25px;
}

.question-textarea{
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1.5rem;
}

.test-title{
	font-family: "Montserrat";
	font-size: 1.5rem;
	margin: 10px 14px;
  margin-bottom: 20px;
}

.multiselect-container{
  margin: 2%;
}

.multiselect-title{
	font-family: "Montserrat";
	font-size: 1.2rem;
	margin: 10px 14px;
  margin-bottom: 20px;
  cursor:pointer;
}

.multiselect-subtitle{
	font-family: "Montserrat";
	font-size: 1rem;
  margin-bottom: 20px;
  cursor:pointer;
  margin: 2%;
}


.answer-text{
  font-family: "Montserrat";
  font-size: 1.15rem;
  margin-left: 30px;
  cursor:pointer;
}

.test-name{
  display:inline-block;
}

.score{
  display: inline-block;
  float: right;
  position: absolute;
  right: 50px;
}

.rate-container{
  display: flex;
  width: 101%;
  overflow-x: hidden;
}

.rate-example-container{
  display: inline;
  width: 50%;
  background-color: #f8f8f8;
  box-shadow: inset 1px 0px 2px #adadad;
  height: 98vh;
  overflow-y: auto;
}

.rating-example-title{
  margin-top: 70px;
  font-size: 1.25rem;
  margin-left: 15px;
  font-family: "Montserrat";
}

.mark{
  width:24px;
  height:24px;
  position: absolute;
  left: 62px;
}

.loading-box{
  position: fixed;
  left: 35%;
  width:30%;
  top: 10%;
}

.loading-box-img{
  width:100%;
}

.lbb-container{
  font-family: "Montserrat";
  background-color: #a2ccfc;
  text-align: center;
}

.lbb-fill{
  background-color: #305bed;
  width: 20%;
  padding: 2px;
  height: 16px;
}

.lbb-text{
  position: absolute;
  text-align: center;
  width: 100%;
}


.edit-icon{
  height: 20px;
  margin-left: 15px;
  opacity: .5;
  cursor:pointer;
}

.edit-icon:hover{
  opacity: .85;
}

.contrib-container{
  position: absolute;
  left: calc(50% - 124px);
  top: 8px;
  color: #26a6b6;
}

.darken{
  opacity: 1;
}

.tag-container{
  margin-left: 15px;
  display: inline;
}

.tag{
  display: inline-block;
  background-color: #ababab;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  color: white;
  font-weight: 800;
  cursor: pointer;
  margin-right: 10px;
  font-size: 1rem;
}

.default-WH{
  display:inline-block;
}

.highlighted-WH{
  display: inline-block;
  background-color: #ddadf2;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.side-ans{
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}

.ans-sub{
  display: inline-block;
  color: #ea2727;
}

.rate-box{
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 5px 0px;
  margin-top: 2px;
  display: inline-block;
  margin: 5px 8px;
}

.rate-message{
  display: inline-block;
  font-family: 'Quicksand';
  color: #538fbd;
  font-weight: bold;
  font-style: normal;
}

.rate-btn{
  background-color: #e6e6e6;
  display: inline-block;
  padding: 5px 15px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: "Quicksand";
  cursor:pointer;
  font-size: .85rem;
}

.selected{
  background-color: #8282f4;
  color: white;
}

.faded{
  opacity: .6;
  cursor: default;
}

.heavy-fade{
  opacity: .3;
  cursor: default;
}

.rate-text{
  font-family: "Quicksand";
  display: inline-block;
  font-size: 1.2rem;
}

.rate-box-container{

}

.answer-block{
  display: inline-block;
  margin: 10px;
  border-radius: 5px;
  font-family: "Montserrat";
  margin-top: 10px;
  position: relative;
}

.ans-container{
  margin: 0px 10px 0px 10px;
  border-radius: 5px;
}

.answer-block-text-container{
  font-size: 1.15rem;
  padding: 3px 15px;
  position: relative;
  display: flex;
  width: 100%;
}

.answer-block-text{
  display: inline-block;
  cursor: default;
  vertical-align: top;
  margin-top: 8px;
}

.answer-block-rating{
  display: inline-block;
  margin-left: 8px;
  padding: 2px 4px;
  cursor: pointer;
}

.block-rate-btn{
  background-color: #e6e6e6;
  display: inline-block;
  padding: 5px 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: "Quicksand";
  cursor: pointer;
  border-radius: 5px;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.07);
  font-size: 10px;
  font-weight: bold;
  margin-top: 7px;
}

.rate-img{
  width: 25px;
  height: 25px;
}

.ans-box{
  background-color:#f8f2eb;
}

.ans-btn{
  border-radius: 3px;
}

.red{
  background-color:#ff4b25;
}
.green{
  background-color:#69dd87;
}
.score{
  position: absolute;
  right: 50px;
  top: 10px;
}

.title-input {
    font-size: 1.75rem;
    margin-top: 2px;
}


.undo-btn{
  display: inline-block;
  margin-left: 1%;
  font-family: 'Montserrat';
  color: #5690db;
  cursor: pointer;
}


.contribution-counter{
  font-family: "Montserrat";
  font-size: 2rem;
  color: #00a7c8;
  display: inline-block;
  position: absolute;
  top: 15%;
  right: 3%;
}

.source-text{
  font-size: .75rem;
  background-color: #f4f9db;
  padding: 5px 10px;
}

.no-context-btn{
  display: inline-block;
  margin-left: 1%;
  font-family: 'Montserrat';
  color: #bfd6f3;
  cursor: pointer;
}

.context-btn{
  display: inline-block;
  margin-left: 1%;
  font-family: 'Montserrat';
  color: #f54646;
  cursor: pointer;
}

.light-blue{
  color: #6a6abf;
}

.blue{
  background-color: #cae8f5;
}

.numeric-flag{
  margin-top: 45%;
  position: relative;
  display: inline-block;
  width: 1rem;
  margin-left: 30%;
  cursor: pointer;
}

.load-more-btn{
  background-color: #b7d5e5;
  width: 100%;
  margin-top: 2%;
  text-align: center;
  font-family: "Montserrat";
  font-size: 1.5rem;
  padding: .5% 0%;
  cursor: pointer;
  color: #535252;
}

.loading-ring{
  height: 5%;
}

.toolbar{
  position: fixed;
  top: 0%;
  z-index: 999999;
}

.example-box{
  margin: 15px;
  background-color: white;
  margin-bottom: 25px;
}

.example-question-text{
  font-size: 1rem;
  font-family: "Montserrat";
}

.example-subtext{
  display: inline;
  margin-left: 15px;
  font-family: "Montserrat";
  font-size: .8rem;
}

.example-change-container{
  font-family: "Montserrat";
  font-size: .8rem;
  margin-left: 15px;
}

.example-change-text{
  display: inline;
}

.example-change-input-box{
  display: inline;
}

.example-change-input{
  width: 37px;
  margin-left: 5px;
}

.line-break{
  color: #ff5b5b;
  opacity: .7;
}

.example-checkmark{
  width: 15px;
  margin-left: 8px;
  margin-top: 0px;
}

.toolbar-image{
  height: 35px;
  padding: 5px;
  margin-left: 20px;
  opacity: .4;
  cursor: pointer;
}

.toolbar-image:hover{
  opacity: .7;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    background-color: #3d3d3d;
    font-size: 1rem;
    visibility: hidden;
    width: 120px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
